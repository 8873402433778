import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const PrivacyPolicy = ({ data }) => {
	const policy = data.strapiPrivacy;

	return (
		<Layout>
			<GatsbySeo
				title="Privacy Policy"
				description="Roots & Routes is committed to protecting your personal information. We want you to understand our Privacy Policy and what it means to you."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/privacy-policy",
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/privacy-policy",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/privacy-policy",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/privacy-policy",
				// 	},
				// ]}
			/>
			<div className="my-20">
				<h1 className="font-bold">Privacy Policy</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					We’re committed to protecting your personal information
				</h2>
				<ReactMarkdown
					className="prose prose-sm sm:prose"
					children={policy.description}
				/>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query PrivacyPolicyQuery {
		strapiPrivacy {
			name
			description
		}
	}
`;

export default PrivacyPolicy;
